import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import PricingTable from "../PricingTable";
import Section from "../Div";
import Cta from "../Cta";

export default function WebDevelopment() {
  return (
    <>
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Website Design And Development"
              subtitle="Our Service"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Techlone Global redefines digital presence through Website
                Design. We blend aesthetics with functionality, creating
                immersive online experiences. Our designs are more than just
                pixels; they're gateways that engage, inspire, and drive
                meaningful connections with your audience. Step into the digital
                realm with Techlone Global's Website Development. We don't just
                code; we engineer user-centric platforms that transcend the
                ordinary. Our focus: seamless navigation, robust
                functionalities, and a digital landscape that amplifies your
                brand's voice.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/service_4.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <SectionHeading
          title="Providing best <br/>pricing for client"
          subtitle="Pricing & Packaging"
        />
        <Spacing lg="85" md="40" />

        <Section className="position-relative">
          <Section className="row">
            <Section className="col-lg-4">
              <PricingTable
                title="BASIC WEBSITE"
                price="349"
                currency="$"
                description="Best Value for Money Guaranteed!"
                features={[
                  "1 Page Website Design",
                  "1 Banner Design",
                  "Contact/Query Form",
                  "Unlimited Revisions",
                  "Complete W3C Certified HTML",
                  "Value Added Services",
                  "Complete Deployment",
                  "Dedicated Project Manager",
                  "100% Ownership Rights",
                  "100% Satisfaction Guarantee",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Website-Basic"
              />
              <Spacing lg="25" md="25" />
            </Section>

            <Section className="col-lg-4">
              <PricingTable
                title="STARTUP WEBSITE"
                price="999"
                currency="$"
                description="Flexibilty and Value for Growing Businesses"
                features={[
                  "Free Domain Registration",
                  "Unique 5 Pages Website Design",
                  "Custom, Interactive, Dynamic & High End Web Design",
                  "Custom WordPress Development",
                  "8 Stock Images",
                  "5 Banner Designs",
                  "Sliding Banners",
                  "Special Hoover Effects",
                  "Content Management System (WordPress)",
                  "Mobile Responsive",
                  "Google Friendly Sitemap",
                  "Search Engine Submission",
                  "Complete W3C Certified HTML",
                  "Industry Specified Team Of Expert Designers And Developers",
                  "Mobile Responsive",
                  "Value Added Services",
                  "Complete Deployment",
                  "Dedicated Project Manager",
                  "100% Ownership Rights",
                  "100% Satisfaction Guarantee",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Website-Startup"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-4">
              <PricingTable
                title="CORPORATE WEBSITE"
                price="2599"
                currency="$"
                description="Answer to All your Branding Needs"
                features={[
                  "Free Domain Registration",
                  "Unique 5 Pages Website Design",
                  "Custom, Interactive, Dynamic & High End Web Design",
                  "Custom WordPress Development",
                  "Interactive Sliding Banners",
                  "Up To 15 Custom Made Banner Designs",
                  "15 Stock Images",
                  "Unlimited Revisions",
                  "Special Hoover Effects",
                  "Content Management System",
                  "Mobile Responsive",
                  "Custom Dynamic Forms (Optional)",
                  "Signup Area (For Newsletters, Offers Etc.)",
                  "Live Feeds Of Social Networks Integration (Optional)",
                  "Google Friendly Sitemap",
                  "Search Engine Submission",
                  "Complete W3C Certified HTML",
                  "Industry Specified Team Of Expert Designers And Developers",
                  "Mobile Responsive",
                  "Value Added Services",
                  "Complete Deployment",
                  "Dedicated Project Manager",
                  "100% Ownership Rights",
                  "100% Satisfaction Guarantee",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Website-Corporate"
              />
              <Spacing lg="25" md="25" />
            </Section>
          </Section>
        </Section>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/appointment"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
