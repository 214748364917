import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Cta from "../Cta";

export default function SmartContract() {
  return (
    <>
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading title="Smart Contract Development" subtitle="Our Service">
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                At Techlone Global, 3D Modelling isn't just about shapes; it's
                about bringing imagination to life. We sculpt virtual realities,
                turning ideas into intricately detailed 3D models. From
                architecture to product visualization, our creations bridge the
                gap between vision and reality.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/service_10.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/appointment"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
