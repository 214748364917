import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import PricingTable from "../PricingTable";
import Section from "../Div";
import Cta from "../Cta";

export default function Modeling() {
  return (
    <>
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading title="3D Modeling" subtitle="Our Service">
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                At Techlone Global, 3D Modelling isn't just about shapes; it's
                about bringing imagination to life. We sculpt virtual realities,
                turning ideas into intricately detailed 3D models. From
                architecture to product visualization, our creations bridge the
                gap between vision and reality.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/service_1.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <SectionHeading
          title="Providing best <br/>pricing for client"
          subtitle="Pricing & Packaging"
        />
        <Spacing lg="85" md="40" />

        <Section className="position-relative">
          <Section className="row">
            <Section className="col-lg-4">
              <PricingTable
                title="BASIC PACKAGE"
                price="400"
                currency="$"
                description="Best Value for Money Guaranteed!"
                features={[
                  "Simple 3D Model Product Creation",
                  "Low To Moderate Complexity",
                  "Single Revision",
                  "Delivery In Standard Formats",
                  "Enhanced File Formats",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/3dModeling-Basic"
              />
              <Spacing lg="25" md="25" />
            </Section>

            <Section className="col-lg-4">
              <PricingTable
                title="STANDARD PACKAGE"
                price="900"
                currency="$"
                description="Flexibilty and Value for Growing Businesses"
                features={[
                  "Moderate Complexity 3D Product Modeling",
                  "Custom Textures And Materials",
                  "Two Revisions",
                  "Delivery In Standard Formats",
                  "Enhanced File Formats",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/3dModeling-Standard"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-4">
              <PricingTable
                title="PREMIUM PACKAGE"
                price="3500"
                currency="$"
                description="Answer to All your Branding Needs"
                features={[
                  "High Complexity 3D product Models",
                  "Intricate Details And Textures",
                  "Three Revisions",
                  "Ultra-High-Resolution Output",
                  "Premium File Formats",
                  "Priority Support",
                  "Enhanced File Formats",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/3dModeling-Premium"
              />
              <Spacing lg="25" md="25" />
            </Section>
          </Section>
        </Section>
      </Div>
      
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/appointment"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
