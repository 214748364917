import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pageTitle } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import EmailTemplate from "../EmailTemplate/EmailTemplate";
import axios from "axios"; // Import axios
import ReactDOMServer from "react-dom/server"; // Import ReactDOMServer
import { API_BASE_URL } from "../../constants";

export default function ContactPage() {
  pageTitle("Contact Us");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const sendMail = async (
    fullName,
    email,
    projectType,
    mobile,
    scopeOfProject
  ) => {
    try {
      const emailBody = ReactDOMServer.renderToString(
        <EmailTemplate
          fullName={fullName}
          email={email}
          projectType={projectType}
          mobile={mobile}
          scopeOfProject={scopeOfProject}
        />
      );

      await axios.post(`${API_BASE_URL}/message/create`, {
        subject: fullName,
        text: emailBody,
      });

      await axios.post(`${API_BASE_URL}/email/send/`, {
        subject: fullName,
        text: emailBody,
      });



      console.log("Message sent successfully");
      

    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    
    const fullName = formData.get("fullName");
    const email = formData.get("email");
    const projectType = formData.get("projectType");
    const mobile = formData.get("mobile");
    const message = formData.get("message");

    // Client-side validation
    if (!fullName || !email || !projectType || !mobile || !message) {
      setError("All fields are required.");
      return;
    }

    setError(null); // Clear any previous error messages

    try {
      // Call sendMail function with extracted form data
      await sendMail(fullName, email, projectType, mobile, message);
      navigate("/success", { state: { fromContactOrServiceForm: true } });

      event.target.reset(); // Reset the form fields
    } catch (error) {
      // Handle error
      console.error("Error:", error);
    }
  };

  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form onSubmit={handleSubmit} className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input type="text" name="fullName" className="cs-form_field" required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input type="email" name="email" className="cs-form_field" required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input type="text" name="projectType" className="cs-form_field" required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input type="text" name="mobile" className="cs-form_field" required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  name="message"
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  required
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button type="submit" className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
            {error && <p className="error">{error}</p>}
          </Div>
        </Div>
      </Div>
    </>
  );
}
