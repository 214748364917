import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import PricingTable from "../PricingTable";
import Section from "../Div";
import Cta from "../Cta";


export default function GraphicDesignPage() {
  return (
    <>
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading title="Graphic Designing" subtitle="Our Service">
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Techlone Global pioneers in Graphics Design, where creativity
                meets strategy. Our designs aren't just visually stunning;
                they're crafted to amplify your brand's essence. We breathe life
                into ideas, translating them into captivating visuals that
                resonate and leave an indelible mark.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/service_2.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <SectionHeading
          title="Providing best <br/>pricing for client"
          subtitle="Pricing & Packaging"
        />
        <Spacing lg="85" md="40" />


        <Section className="position-relative">
          <Section className="row">
            <Section className="col-lg-4">
              <PricingTable
                title="Logo Starter"
                price="90"
                currency="$"
                description="Best Value for Money Guaranteed!"
                features={[
                  "1 Custom Logo Design Concepts",
                  "1 Creative Designer",
                  "1 Revisions",
                  "Final Files Format (JPG & PNG)",
                  "100% Unique Design Guarantee",
                  "100% Satisfaction Guarantee",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Logo-Starter"
              />
              <Spacing lg="25" md="25" />
            </Section>

            <Section className="col-lg-4">
              <PricingTable
                title="Logo Business"
                price="310"
                currency="$"
                description="Flexibilty and Value for Growing Businesses"
                features={[
                  "3 Custom Logo Design Concepts",
                  "2 Creative Designer",
                  "Unlimited Revisions",
                  "Stationary Design (Business Card, Letterhead, Envelope)",
                  "All Final Files Format (EPS, PNG, GIF, JPG, PDF)",
                  "100% Unique Design Guarantee",
                  "100% Satisfaction Guarantee",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Logo-Business"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-4">
              <PricingTable
                title="Logo Executive"
                price="499"
                currency="$"
                description="Answer to All your Branding Needs"
                features={[
                  "5-6 Custom Logo Design Concepts",
                  "4 Creative Designer",
                  "Unlimited Revisions",
                  "Icon Design & Social Media Banners",
                  "Stationary Design (2 Business Card Designs, Letterhead, Envelope)",
                  "MS Word Letterhead And Email Signature",
                  "Stationary Design (2 Business Card Designs, Letterhead, Envelope)",
                  "All Final Files Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)",
                  "100% Unique Design Guarantee",
                  "100% Satisfaction Guarantee",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Logo-Executive"
              />
              <Spacing lg="25" md="25" />
            </Section>
          </Section>
        </Section>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/appointment"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
