import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import axios from 'axios';
import { API_BASE_URL } from '../../../constants';

const columns = [
  {
    title: 'Clients',
    dataIndex: 'subject',
    key: 'subject',
  },
  {
    title: 'Email',
    dataIndex: 'text',
    key: 'text',
    render: text => (
      <div 
        style={{ 
          maxWidth: 1200, 
          overflow: 'auto', 
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word' 
        }}
        dangerouslySetInnerHTML={{ __html: text }} 
      />
    ),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
];

const AdminMessages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/message/all`);
        const messagesWithDate = response.data.map(message => ({
          ...message,
          date: new Date(parseInt(message._id.toString().substring(0, 8), 16) * 1000).toLocaleString()
        }));
        setMessages(messagesWithDate);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMessages();
  }, []);

  return <Table columns={columns} dataSource={messages} rowKey="_id" />;
};

export default AdminMessages;