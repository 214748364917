import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import CustomCursor from '../CustomCursor';
import Footer from '../Footer';
import Header from '../Header';

export default function Layout({ headerVariant }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  return (
    <>
      <Header variant={headerVariant} />
      <Outlet />
      <CustomCursor />
      <Footer />
      <TawkMessengerReact
        propertyId="6576d21507843602b8009f0d"
        widgetId="1hhc299kh"
        ref={tawkMessengerRef}
      />
    </>
  );
}
