import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import PricingTable from "../PricingTable";
import Section from "../Div";
import Cta from "../Cta";

export default function TwitchPackages() {
  return (
    <>
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading title="Twitch Packages" subtitle="Our Service">
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Transform your Twitch channel with our all-inclusive package.
                Gain custom overlays and graphics that capture your brand's
                essence, while audio enhancements ensure an immersive
                experience. Manage your stream effortlessly with our tools,
                engage your audience with proven strategies, and track growth
                with in-depth analytics. Elevate every aspect of your stream and
                fuel its success with our comprehensive Twitch package.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/service_8.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <SectionHeading
          title="Providing best <br/>pricing for client"
          subtitle="Pricing & Packaging"
        />
        <Spacing lg="85" md="40" />

        <Section className="position-relative">
          <Section className="row">
            <Section className="col-lg-4">
              <PricingTable
                title="STATIC PACKAGE"
                price="350"
                currency="$"
                description="Best Value for Money Guaranteed!"
                features={[
                  "Logo",
                  "Banner",
                  "Screen Overlay, Facecam Overlay, Chatbox Overlay",
                  "4 Screens",
                  "Basic Alerts",
                  "Basic Panels",
                  "Single Revision",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Twitch-Static"
              />
              <Spacing lg="25" md="25" />
            </Section>

            <Section className="col-lg-4">
              <PricingTable
                title="ANIMATED PACKAGE"
                price="500"
                currency="$"
                description="Flexibilty and Value for Growing Businesses"
                features={[
                  "Animated Logo",
                  "Animated Banner",
                  "Animated: Screen Overlay, Facecam Overlay, Chatbox Overlay",
                  "Animated Screens",
                  "Animated Alerts",
                  "Animated Panels",
                  "Single Revision",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Twitch-Animated"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-4">
              <PricingTable
                title="Custom Package"
                price=""
                currency=""
                description=""
                features={[
                  "Get a Quote For your Custom Package ",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Twitch-Custom"
              />
              <Spacing lg="25" md="25" />
            </Section>
          </Section>
        </Section>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/appointment"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
