import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Modal,
    Input,
    Upload,
    Pagination,
    Row,
    Col,
    Popconfirm,
    Typography,
    Spin,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { uniqBy } from "lodash";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { API_BASE_URL } from "../../../constants";

function BlogController() {
    const [blogs, setBlogs] = useState([]);
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        currentBlog: {
            title: "",
            content: "",
            image: "",
            category: "",
        },
        isEditing: false,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [blogsPerPage] = useState(4);
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const { currentBlog, isEditing } = state;

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${API_BASE_URL}/blog/all`)
            .then((res) => {
                setBlogs(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching blogs:", error);
                setLoading(false);
            });
    }, []);

    const handleUpload = ({ fileList }) => {
        // Update the state and show the latest uploaded files
        setFileList(fileList);
    };

    const handleBeforeUpload = (file) => {
        setState((prevState) => ({
            ...prevState,
            currentBlog: { ...prevState.currentBlog, image: file },
        }));
        setFileList([file]);
        return false; // return false to stop automatic upload
    };

    const handleOpen = (blog) => {
        if (blog._id) {
            setState({
                currentBlog: { ...blog, id: blog._id },
                isEditing: true,
            });
        } else {
            setState({
                currentBlog: {
                    title: "",
                    content: "",
                    image: "",
                    category: "",
                },
                isEditing: false,
            });
        }
        setOpen(true);
    };

    const handleClose = () => {
        setState({
            currentBlog: {
                title: "",
                content: "",
                image: "",
                category: "",
            },
            isEditing: false,
        });
        setOpen(false);
        setFileList([]); // Reset the fileList as well
    };

    const handleSave = () => {
        const blogToSave = { ...state.currentBlog };
        const formData = new FormData();
        Object.keys(blogToSave).forEach((key) => {
            // If the user hasn't selected a new image, don't include it in formData
            if (key !== "image" || blogToSave[key] instanceof File) {
                formData.append(key, blogToSave[key]);
            }
        });

        setLoading(true);

        if (blogToSave.id) {
            axios
                .put(`${API_BASE_URL}/blog/edit/${blogToSave.id}`, formData)
                .then(() => {
                    // Fetch the updated blog data from the server
                    axios
                        .get(`${API_BASE_URL}/blog/id/${blogToSave.id}`) // Use the blog's id instead of its slug
                        .then((res) => {
                            const updatedBlog = res.data;
                            // Update the blogs state with the updated blog data
                            const updatedBlogs = blogs.map((blog) =>
                                blog._id === updatedBlog._id
                                    ? updatedBlog
                                    : blog
                            );
                            setBlogs(updatedBlogs);
                            setState((prevState) => ({
                                ...prevState,
                                currentBlog: {
                                    title: "",
                                    content: "",
                                    image: "",
                                    category: "",
                                },
                            }));
                            handleClose();
                            setLoading(false);
                        });
                });
        } else {
            axios.post(`${API_BASE_URL}/blog/create`, formData).then((res) => {
                setBlogs([...blogs, res.data]);
                setState((prevState) => ({
                    ...prevState,
                    currentBlog: {
                        title: "",
                        content: "",
                        image: "",
                        category: "",
                    },
                }));
                handleClose();
                setLoading(false);
            });
        }
    };

    const handleChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            currentBlog: {
                ...prevState.currentBlog,
                [e.target.name]: e.target.value,
            },
        }));
    };

    const handleDelete = (id) => {
        axios
            .delete(`${API_BASE_URL}/blog/delete/${id}`)
            .then(() => {
                const updatedBlogs = blogs.filter((blog) => blog._id !== id);
                setBlogs(updatedBlogs);
            })
            .catch((error) => {
                console.error("Error deleting blog post:", error);
            });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

    return (
        <div>
            {uniqBy(blogs, "category").map((blog) => (
                <div key={blog.category}>
                    <h2>{blog.category}</h2>
                    <Row gutter={16}>
                        {blogs
                            .filter((b) => b.category === blog.category)
                            .map((blog) => (
                                <Col span={6} key={blog._id}>
                                    <Card
                                        cover={
                                            <img
                                                alt={blog.title}
                                                src={blog.image}
                                                style={{
                                                    maxHeight: "200px",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        }
                                        style={{
                                            height: "350px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Card.Meta
                                            title={
                                                <Typography.Text
                                                    ellipsis
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    {blog.title}
                                                </Typography.Text>
                                            }
                                            description={
                                                <Typography.Text
                                                    ellipsis={{
                                                        rows: 3,
                                                        expandable: false,
                                                    }}
                                                >
                                                    {blog.content}
                                                </Typography.Text>
                                            }
                                        />
                                        <Button
                                            type="primary"
                                            onClick={() => handleOpen(blog)}
                                        >
                                            Edit
                                        </Button>
                                        <Popconfirm
                                            title="Are you sure to delete this blog?"
                                            onConfirm={() =>
                                                handleDelete(blog._id)
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="danger">
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                </div>
            ))}
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => handleOpen({})}
            >
                New Blog
            </Button>
            <Spin spinning={loading}>
                <Modal
                    title={isEditing ? "Edit Blog" : "New Blog"}
                    visible={open}
                    onCancel={handleClose}
                    onOk={handleSave}
                >
                    <Input
                        name="title"
                        placeholder="Title"
                        value={currentBlog.title}
                        onChange={handleChange}
                    />
                    <ReactQuill
                        value={currentBlog.content}
                        onChange={(value) =>
                            handleChange({ target: { name: "content", value } })
                        }
                    />
                    <Input
                        name="category"
                        placeholder="Category"
                        value={currentBlog.category}
                        onChange={handleChange}
                    />
                    <Upload
                        name="image"
                        listType="picture-card"
                        fileList={fileList}
                        beforeUpload={handleBeforeUpload}
                        onChange={handleUpload}
                        onRemove={() => setFileList([])}
                    >
                        {fileList.length >= 1 ? null : "Upload"}
                    </Upload>
                </Modal>
            </Spin>
            <Pagination
                current={currentPage}
                total={blogs.length}
                pageSize={blogsPerPage}
                onChange={handlePageChange}
            />
        </div>
    );
}

export default BlogController;
