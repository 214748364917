import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import PricingTable from "../PricingTable";
import Section from "../Div";
import Cta from "../Cta";

export default function DigitalMarketing() {
  return (
    <>
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading title="Digital Marketing & SEO" subtitle="Our Service">
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Our dedicated team of digital marketing professionals is skilled at navigating the complexities of the digital landscape. With a strong focus on data-driven decision-making and careful attention to detail, we help your brand go beyond limits and build long-lasting connections with your target audience.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/service_3.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <SectionHeading
          title="Providing best <br/>pricing for client"
          subtitle="Pricing & Packaging"
        />
        <Spacing lg="85" md="40" />

        <Section className="position-relative">
          <Section className="row">
            <Section className="col-lg-4">
              <PricingTable
                title="BASIC PACKAGE"
                price="350"
                currency="$"
                description="Best Value for Money Guaranteed!"
                features={[
                  "Creative Design",
                  "8 Post per month",
                  "2 videos/animations per month",
                  "Brand Strategy",
                  "Advertising Campaign",
                  "Social Media Management",
                  "Content Management",
                  "Ad budget is on Client",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/DigitalMarketing-Basic"
              />
              <Spacing lg="25" md="25" />
            </Section>

            <Section className="col-lg-4">
              <PricingTable
                title="STANDARD PACKAGE"
                price="500"
                currency="$"
                description="Flexibilty and Value for Growing Businesses"
                features={[
                  "Creative Design",
                  "12 Post per month",
                  "3 videos/animations per month",
                  "Brand Strategy",
                  "Advertising Campaign",
                  "Social Media Management",
                  "Comments Management",
                  "Content Management",
                  "Ad budget is on Client",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/DigitalMarketing-Standard"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-4">
              <PricingTable
                title="PREMIUM PACKAGE"
                price="650"
                currency="$"
                description="Answer to All your Branding Needs"
                features={[
                  "Creative Design",
                  "15 Post per month",
                  "5 videos/animations per month",
                  "Brand Strategy",
                  "Advertising Campaign",
                  "Social Media Management",
                  "Comments Management",
                  "Messages Management",
                  "Content Management",
                  "Ad budget is on Client",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/DigitalMarketing-Premium"
              />
              <Spacing lg="25" md="25" />
            </Section>
          </Section>
        </Section>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/appointment"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
