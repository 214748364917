import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Cta from "../Cta";

export default function Blockchain() {
  return (
    <>
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Blockchain Development"
              subtitle="Our Service"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                At Techlone Global, blockchain development isn't just about
                coding; it's about revolutionizing digital landscapes. We
                architect decentralized solutions, transforming concepts into
                robust blockchain applications. From financial services to
                supply chain management, our developments bridge the chasm
                between innovation and implementation.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/service_9.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Get Quotation"
          btnLink="/service-form/Blockchain-Development"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
