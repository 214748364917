import React from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({withIcon, title}) {
  const emailAddress = 'contact@houseofdumplings.info';
  const phoneNumber = '+923456568977';

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleContactClick = (contactType) => {
      if (contactType === 'email') {
          window.location.href = `mailto:${emailAddress}`;
      } else if (contactType === 'phone' && isMobile) {
          window.location.href = `tel:${phoneNumber}`;
      }
  };

  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li onClick={() => handleContactClick('phone')} style={{ cursor: 'pointer' }}>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          +1 (682) 245-0161
        </li>
        <li onClick={() => handleContactClick('email')} style={{ cursor: 'pointer' }}>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          contact@techloneglobal.com
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
          Texas, United States
        </li>
      </ul>
    </>
  )
}
