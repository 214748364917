import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import PricingTable from "../PricingTable";
import Section from "../Div";
import Cta from "../Cta";

export default function Modeling() {
  return (
    <>
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading title="Animation" subtitle="Our Service">
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Techlone Global pioneers the art of visual storytelling through
                meticulous 2D and 3D animations. With a keen eye for detail and
                a blend of creativity and technical finesse, our animations
                breathe life into concepts. From dynamic explainer videos to
                immersive brand narratives, we craft visually stunning
                animations that captivate audiences and elevate brand
                communication in the digital sphere.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/service_5.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <SectionHeading
          title="Providing best <br/>pricing for client"
          subtitle="Pricing & Packaging"
        />
        <Spacing lg="85" md="40" />

        <Section className="position-relative">
          <Section className="row">
            <Section className="col-lg-4">
              <PricingTable
                title="BASIC PACKAGE"
                price="549"
                currency="$"
                description="Best Value for Money Guaranteed!"
                features={[
                  "30 Second Video",
                  "Professional Script",
                  "Storyboard",
                  "Animation",
                  "Voice - Over & Sound Effects",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Animation-Basic"
              />
              <Spacing lg="25" md="25" />
            </Section>

            <Section className="col-lg-4">
              <PricingTable
                title="PREMIUM PACKAGE"
                price="1049"
                currency="$"
                description="Flexibilty and Value for Growing Businesses"
                features={[
                  "75 Second Video",
                  "Professional Script",
                  "Sample Theme",
                  "Storyboard",
                  "Animation",
                  "Voice - Over & Sound Effects",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Animation-Premium"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-4">
              <PricingTable
                title="ULTIMATE PACKAGE "
                price="1849"
                currency="$"
                description="Answer to All your Branding Needs"
                features={[
                  "120 Second Video",
                  "Professional Script",
                  "Sample Theme",
                  "Storyboard",
                  "Animation",
                  "Voice - Over & Sound Effects",
                ]}
                btnText="Purchase Now"
                btnLink="/service-form/Animation-Ultimate"
              />
              <Spacing lg="25" md="25" />
            </Section>
          </Section>
        </Section>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/appointment"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
