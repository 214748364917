import React from "react";
import withAuth from "./withAuth";
import { Layout, Menu, Typography, Button } from "antd";
import { Link, Routes, Route } from "react-router-dom";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import BlogController from "./Pages/BlogController";
import MessageController from "./Pages/MessageController";

const { Header, Content, Sider, Footer } = Layout;
const { Title } = Typography;

const Admin = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };
    return (
        <div style={{ backgroundColor: "#f0f2f5", minHeight: "100vh" }}>
            <Layout style={{ minHeight: "100vh", backgroundColor: "#f0f2f5" }}>
                <Sider width={200} theme="dark">
                    <Title
                        level={3}
                        style={{ color: "white", padding: "20px" }}
                    >
                        Admin Panel
                    </Title>
                    <Menu
                        mode="vertical"
                        theme="dark"
                        defaultSelectedKeys={["1"]}
                    >
                        <Menu.Item key="1" icon={<AppstoreAddOutlined />}>
                            <Link to="/admin/blog-controller">
                                Blog Controller
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="2" icon={<AppstoreAddOutlined />}>
                            <Link to="/admin/message-controller">
                                Message Controller
                            </Link>
                        </Menu.Item>
                    </Menu>
                    <Button
                        onClick={handleLogout}
                        style={{
                            marginTop: "20px",
                            backgroundColor: "#f5222d",
                            color: "white",
                            borderColor: "#f5222d",
                        }}
                    >
                        Logout
                    </Button>
                </Sider>
                <Layout className="site-layout">
                    <Header
                        className="site-layout-background"
                        style={{ padding: 0, backgroundColor: "#001529" }}
                    />
                    <Content
                        style={{ margin: "16px", backgroundColor: "#f0f2f5" }}
                    >
                        <div
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                minHeight: 360,
                                backgroundColor: "#e6e6e6",
                            }}
                        >
                            <Routes>
                                <Route
                                    path="blog-controller"
                                    element={<BlogController />}
                                />
                                <Route
                                    path="message-controller"
                                    element={<MessageController />}
                                />
                            </Routes>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
};

export default withAuth(Admin);
