import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialThumb: '/images/testimonial_1.png',
      testimonialText:
        'Techlone Global exceeded my expectations with their innovative solutions and attention to detail. Highly recommend for anyone looking to elevate their digital presence.',
      avatarName: '',
      avatarDesignation: 'ARCPHOENIX',
      ratings: '4',
    },
    {
      testimonialThumb: '/images/testimonial_2.jpg',
      testimonialText:
        "With Techlone Global, you're not just getting a service; you're gaining a committed partner in digital success. Their personalized approach and expertise make all the difference.",
      avatarName: '',
      avatarDesignation: 'STEALS',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/testimonial_3.jpg',
      testimonialText:
        'Working with Techlone Global was a breath of fresh air. Their blend of creativity and technical prowess resulted in a digital masterpiece for our business. From website development to social media management, their team handled every aspect with precision and professionalism.',
      avatarName: '',
      avatarDesignation: 'SIRENSEPITAPH',
      ratings: '4.5',
    },
    {
      testimonialThumb: '/images/testimonial_4.png',
      testimonialText:
        'Techlone Global delivered beyond my expectations, crafting a seamless digital experience with their unmatched web development expertise.',
      avatarName: '',
      avatarDesignation: 'DRUMSON',
      ratings: '4.4',
    },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
