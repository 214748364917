import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Div from "../Div";
import Spacing from "../Spacing";
import GraphicDesignPage from "../Services/GraphicsDesign";
import Modeling from "../Services/3dModeling";
import DigitalMarketing from "../Services/DigitalMarketing";
import WebDevelopment from "../Services/webDevelopement";
import Animation from "../Services/Animation";
import TwitchPackages from "../Services/TwitchPackage";
import Illustrations from "../Services/Illustration";
import Blockchain from "../Services/BlockChain";
import SmartContract from "../Services/SmartContract";
import MobileDevelopment from "../Services/MobileDevelopment";

export default function ServiceDetailsPage() {
  const params = useParams();
  pageTitle(params.serviceDetailsId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  const renderService = (serviceId) => {
    switch (serviceId) {
      case "graphic-design":
        return <GraphicDesignPage />;
      case "3d-modeling":
        return <Modeling />;
      case "digital-marketing":
        return <DigitalMarketing />;
      case "website-design-and-development":
        return <WebDevelopment />;
      case "animation":
        return <Animation />;
      case "twitch-packages":
        return <TwitchPackages />;
      case "mobile-app-design-development":
        return <MobileDevelopment />;
      case "illustration":
        return <Illustrations />
      case "blockchain-development":
        return <Blockchain />
      case "smart-contracts-development":
        return <SmartContract />
      default:
        return null;
    }
  };

  return (
    <>
      <PageHeading
        title="Service Details"
        bgSrc="/images/service_hero_bg.jpeg"
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg="145" md="80" />

      {renderService(params.serviceDetailsId)}
    </>
  );
}
