import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Div from '../Div';
import SectionHeading from '../SectionHeading';

export default function Success() {
  const location = useLocation();

  const redirectedFromContactOrServiceForm = location.state && location.state.fromContactOrServiceForm;

  if (!redirectedFromContactOrServiceForm) {
    return <Navigate to="/404" />;
  }

  pageTitle('Success');
  return (
    <Div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg cs-error_page"
      style={{ backgroundImage: 'url("/images/about_hero_bg.jpeg")' }}
    >
      <Div className="container">
        <SectionHeading
          title="Our Team will contact you shortly"
          subtitle="Thank you for contacting Techlone Global"
          btnText="Back To Home"
          btnLink="/"
          variant="cs-style1 text-center"
        />
      </Div>
    </Div>
  );
}
