import React from "react";

const EmailTemplate = ({
  fullName,
  work,
  appointment,
  email,
  projectType,
  mobile,
  scopeOfProject,
}) => {
  const emailHTML = `
    <html>
      <head>
        <style>
          table {
            border-collapse: collapse;
            width: 100%;
          }
          th, td {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }
          th {
            background-color: #f2f2f2;
          }
        </style>
      </head>
      <body>
      <h2>
      ${
        work
          ? `Lead for ${work}`
          : appointment
          ? "Appointment"
          : "Contact Message"
      }
    </h2>
    
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>${fullName}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Email</td>
              <td>${email}</td>
            </tr>
            <tr>
              <td>Project Type</td>
              <td>${projectType}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>${mobile}</td>
            </tr>
            <tr>
              <td>Message</td>
              <td>${scopeOfProject}</td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  `;

  return <div dangerouslySetInnerHTML={{ __html: emailHTML }} />;
};

export default EmailTemplate;
