import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import PricingTable from "../PricingTable";
import Section from "../Div";
import Cta from "../Cta";

export default function MobileDevelopment() {
  return (
    <>
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading title="Mobile App Design & Development" subtitle="Our Service">
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Innovation meets mobility with Techlone Global's Mobile App
                Design. We architect intuitive interfaces that captivate. Our
                designs aren't just beautiful; they're crafted to enhance user
                experience, ensuring your app stands out in the palm of your
                users' hands. Techlone Global transforms concepts into
                functional realities with Android & iOS App Development. From
                ideation to execution, our team engineers apps that embody
                innovation, functionality, and seamless performance across
                platforms, ensuring your brand shines on every device.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/service_6.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>

    

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Get Quotation"
          btnLink="/service-form/App-Development"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
