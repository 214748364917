import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Pagination from '../Pagination';
import PostStyle2 from '../Post/PostStyle2';
import Div from '../Div';
import Spacing from '../Spacing';
import axios from 'axios';
import { API_BASE_URL } from "../../constants";

export default function BlogPage() {
  pageTitle('Blog');

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/blog/all`);
        // Convert ISO date format to "MM-DD-YYYY" format
        const formattedBlogs = response.data.map(blog => ({
          ...blog,
          dateCreated: formatDate(blog.dateCreated),
        }));
        setBlogs(formattedBlogs);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to format date from ISO to "MM-DD-YYYY"
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`;
    return formattedDate;
  };

  return (
    <>
      <PageHeading
        title='Our Blog'
        bgSrc='/images/blog_hero_bg.jpeg'
        pageLinkText='Blog'
      />
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Div className="row">
          <Div>
            {blogs.map((item, index) => (
              <Div key={index}>
                <PostStyle2
                  thumb={item.image}
                  title={item.title}
                  subtitle={item.content}
                  date={item.dateCreated}
                  category={item.category}
                  href={item.slug}
                />
                {blogs.length > index + 1 && <Spacing lg='95' md='60' />}
              </Div>
            ))}
            <Spacing lg='60' md='40' />
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title='Let’s discuss make <br />something <i>cool</i> together'
          btnText='Apply For Meeting'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  );
}
