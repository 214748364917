import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import FunFact from "../FunFact";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";

export default function AboutPage() {
  pageTitle("About");

  const funfaceData = [
    {
      title: "Global Happy Clients",
      factNumber: "1K",
    },
    {
      title: "Project Completed",
      factNumber: "2K",
    },
    {
      title: "Team Members",
      factNumber: "27",
    },
    {
      title: "Digital products",
      factNumber: "100",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-7">
            <SectionHeading
              title="YOUR TRUSTED PARTNER"
              subtitle="About Our Agency"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Techlone Global: Your Destination for World-Class Digital
                Solutions. Our expertise spans pixel-perfect designs, advanced
                development, and captivating animations, ensuring your global
                digital footprint stands out with tailored innovation and
                excellence. At Techlone Global, we believe in the power of
                innovation to drive progress. Whether it's developing scalable
                web applications, creating intuitive mobile apps, or
                implementing robust enterprise solutions, our commitment to
                quality and client satisfaction is unwavering. We thrive on
                challenges and embrace the ever-evolving nature of the tech
                industry, ensuring that our clients stay ahead in the
                competitive digital landscape.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-1">
            <img
              src="/images/about_img_1.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          title="Our fun fact"
          subtitle="Eight years of trailblazing the digital frontier: Techlone Global, where our legacy of innovation continues to fuel the future of digital excellence."
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
                <img
                  src="/images/about_img_4.png"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Highly experienced people with us"
              subtitle="Why Choose Us"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Consider hiring our digital agency for unparalleled expertise
                and proven results. With a team of seasoned professionals adept
                in diverse digital disciplines such as web design, SEO, social
                media marketing, and content creation, we offer a comprehensive
                suite of services tailored to your specific needs. Our track
                record speaks for itself, with a history of delivering effective
                strategies that drive engagement and propel businesses forward
                in the digital sphere.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                Moreover, our commitment to client satisfaction sets us apart.
                We prioritize clear communication, transparency, and
                collaboration throughout every project, ensuring that your
                vision is realized with precision and excellence. By leveraging
                cutting-edge technologies and innovative approaches, we deliver
                customized solutions that resonate with your target audience and
                yield tangible results. Partner with us, and experience the
                difference of having a dedicated team working tirelessly to
                elevate your brand in the digital landscape.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/appointment"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
